.root {
    width: 100%;
    height: 100%;
    font-family: "Noto Sans KR", sans-serif;
}
.topbar {
    width: 100%;
    height: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.tbViewMore {
    height: 25px;
    display: flex;
    align-items: center;
    font-size: 14px;
    color: rgba(0,0,0,0.6);
}
.tbViewMoreText {
    font-size: 14px;
    margin-left: 3px;
    font-weight: bold;
}

.tbTitle {
    font-size: 22px;
    color: rgba(0,0,0,0.8);
}
.listRoot {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: hidden;
}
.listElement {
    width:100%;
    height: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-color: rgba(0,0,0,0.3);
    border-style: solid;
    border-width: 0 0 2px 0;
}
.leTitle {
    font-size: 16px;
    color: rgba(0,0,0,0.6)
}
.leComment {
    font-size: 14px;
    color: blue;
}
.ntTitle {
    font-size: 22px;
    color: rgba(0,0,0,0.8);
}
.ntElement {
    width:100%;
    border-color: rgba(0,0,0,0.3);
    border-style: solid;
    border-width: 0 0 2px 0;
}
.ntBigText {
    font-size: 32px;
    font-weight: bold;
}
.ntText {
    font-size: 18px;
}
@media (max-width: 1200px) {
    .tbTitle {
        font-size: 16px;
    }
    .leTitle {
        font-size: 14px;
    }
    .listElement {
        height: 25px;
    }
    .leComment {
        font-size: 12px;
    }
    .ntBigText {
        font-size: 26px;
    }
    .ntTitle {
        font-size: 16px;
    }
    .ntText {
        font-size: 14px;
    }
}
