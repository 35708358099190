.root {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    padding-top: 15px;
}
.container:hover > .image {
    box-shadow: rgba(0,0,0,0.7) 5px 5px 5px;
    transform: scale(1.05);
}
.container:hover > .imageDesc {
    transform: scale(1.05);
}
.container {
    width: 200px;
    margin: 0 7px 7px 7px;
    height: fit-content;
    position: relative;
}
.container:hover > .imageDesc {
    opacity: 1;
    height: min-content;
}
.image {
    width: 100%;
    box-shadow: rgba(0,0,0,0.7) 2px 2px 2px;
    transition: box-shadow 0.3s, transform 0.3s;
    border-radius: 5px;
}
.imageDesc {
    height: 0;
    width: 100%;
    top: 0;
    position: absolute;
    background-color: rgba(0,0,0,0.6);
    border-radius: 3px;
    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-template-rows: 1fr;
    align-items: center;
    color: white;
    transition: opacity 0.2s, transform 0.2s;
    opacity: 0;
}
.imageDescC {
    display: flex;
    flex-direction: column;

}
.imageDescTitle {
    font-size: 13px;
}
.imageDescText {
    font-size: 10px;
}
.imageDescIcon {
    font-size: 35px;
}
.videoIcon {
    font-size: 50px;
    color: white;
    position: absolute;
}
@media (max-width: 1200px) {
    .container {
        width: 170px;
        margin: 0 6px 6px 6px;
    }

}
@media (max-width: 910px) {
    .container {
        width: 140px;
        margin: 0 4px 4px 4px;
    }

    .imageDescTitle {
        font-size: 11px;
    }

    .imageDescText {
        font-size: 9px;
    }

    .imageDescIcon {
        font-size: 30px;
    }

    .videoIcon {
        font-size: 40px;
    }
}
