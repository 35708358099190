@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&display=swap');
html, body {
    margin: 0;
    width: 100%;
    height: 100%;
}
#root {
    height: 100%;
    width: 100%;
}
.marginLeft10 {
    margin-left: 10px;
}
.marginLeft30 {
    margin-left: 30px;
}
.marginRight10 {
    margin-right: 10px;
}
.marginRight30 {
    margin-right: 30px;
}
.marginTop10 {
    margin-top: 10px;
}
.marginTop30 {
    margin-top: 30px;
}
.hlWithoutStyle {
    text-decoration: none;
    color: black;
}
.hlWithoutStyleWhite {
    text-decoration: none;
    color: white;
}
.materialIcon {
    font-family: 'Material Icons',sans-serif;
    font-weight: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -moz-font-feature-settings: 'liga';
    -moz-osx-font-smoothing: grayscale;
    border-radius: 50%;
}
@font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/materialicons/v126/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format('woff2');
}
