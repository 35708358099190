#App {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.root {
    width: 80%;
    display: grid;
    grid-template-columns: repeat(2, 50%);
    grid-column-gap: 100px;
    grid-template-rows: 1fr;
    font-family: "Noto Sans KR", sans-serif;
    padding: 28px 0 48px 0;
}
.container {
    display: flex;
    flex-direction: column;
}
.flex {
    display: flex;
    align-items: center;
}
.content {
    display: flex;
    justify-content: center;
    min-height: 100%;
}

.mainContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
}
.textContainer {
    display: flex;
    flex-direction: column;
}
.title {
    font-size: 48px;
    font-weight: 700;
    word-break: keep-all;
}
.semiTitle {
    font-size: 24px;
    font-weight: 700;
}
.mainText {
    font-size: 18px;
    font-weight: 500
}
.alignCenter {
    justify-content: center;
    align-items: center;
}
.buttonIcon {
    font-family: 'Material Icons',sans-serif;
    font-weight: normal;
    font-style: normal;
    line-height: 1;
    font-size: 48px;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -moz-font-feature-settings: 'liga';
    -moz-osx-font-smoothing: grayscale;
    margin-left: 3px;
    border-radius: 50%;
}
.nameCanvas {
    width: 100%;
    height: 150px;
    display: grid;
    grid-template: repeat(6, 1fr) / repeat(6, 1fr);
    grid-gap: 1px 2px;
}
.name {
    font-size: 18px;
    font-weight:300;
}
.noticeContainer {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
}
@media (max-width: 1200px) {
    .title {
        font-size: 36px;
    }
    .semiTitle {
        font-size: 24px;
    }
    .mainText {
        font-size: 16px;
    }
    .name {
        font-size: 16px;
    }
    .root {
        grid-column-gap: 50px;
        width: 85%;
    }
}
@media (max-width: 910px) {
    .title {
        font-size: 32px;
    }
    .semiTitle {
        font-size: 20px;
    }
    .mainText {
        font-size: 14px;
    }
    .name {
        font-size: 14px;
    }
    .root {
        grid-column-gap: 40px;
        width: 90%;
    }
}
