.root {
    width: 100%;
}
.header {
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: 'Noto Sans KR', sans-serif;
}
.sidebar {
    width: 100%;
    height: 50px;
    border-radius: 0 0 80px 80px;
    background-color: #F27979;
    box-shadow: #181126 0px 3px 3px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-family: 'Noto Sans KR', sans-serif;
    text-shadow: #181126 2px 2px 2px;
    transition: height 0.5s;
    color: white;
}
.footer {
    width: 100%;
    height: 180px;
    background-color: #F27979;
    font-family: "Noto Sans KR", sans-serif;
    color: white;
    font-weight: bold;
    padding-top: 15px;
    display: flex;
}
.flexContainer {
    display: flex;
    align-items: center;
}
.title {
    font-size: 32px;
    font-weight: 800;
}
.semiTitle {
    font-size: 24px;
    font-weight: 700;

}
.mainText {
    font-size: 16px;
    font-weight: 500;
}
@media (max-width: 1200px) {
    .title {
        font-size: 28px;
    }
    .semiTitle {
        font-size: 20px;
    }
    .sidebar {
        justify-content: space-evenly;
    }
}
.materialIcons {
    font-family: 'Material Icons',sans-serif;
    font-weight: normal;
    font-style: normal;
    font-size: 60px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -moz-font-feature-settings: 'liga';
    -moz-osx-font-smoothing: grayscale;
}
