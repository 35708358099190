.TSRoot {
    width: 100%;
    height: 20%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1.5fr 3fr;
    grid-gap: 5px 10px
}
.TSContainer {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 10px;
}
.TSElement {
    display: flex;
    align-items: center;
    justify-content: center;
}
.TSInput {
    width: 100%;
    height: 100%;
    border: #F27979 3px solid;
    border-radius: 5px;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
}
.TSInfo {
    font-size: 20px;
    font-weight: bold;
}
.TSInfoBox {
    border: #F27979 4px solid;
    border-radius: 10px;
    background-color: #EEEEEE;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    padding: 10px;
    font-size: 16px;
    word-break: keep-all;
}
.TSOLScoreBox {
    display: inline-flex;
    align-items: center;
}
.TSOLScore {
    width: 3em;
    border: #F27979 2px solid;
    border-radius: 5px;
    font-size: 20px;
    text-align: center;
    margin-left: 5px;
    vertical-align: middle;
}
.TSAmountInfoContainer {
    display: grid;
    grid-template-rows: repeat(4, 1fr);
    grid-template-columns: 1fr;
    grid-row-gap: 10px;
    border: #F27979 5px solid;
    border-radius: 5px;
    padding: 10px;
}
.TSAmountInfoTextC {
    display: flex;
    align-items: baseline;
}
.TSAmountTitle {
    font-size: 20px;
    font-weight: bold;
    word-break: keep-all;
}
.TSAmountInfo {
    font-size: 16px;
    margin-left: 0.5em;
}
.calUnselBox {
    padding: 10% 0 10% 0;
    justify-content: center;
    align-items: center;
    color: rgba(0,0,0,0.5)
}
.calUnselText {

}
.RankInfoContainer {
    height: 300px;
    max-width: 100%;
    display: grid;
    grid-template-rows: 80% 20%;
    grid-template-columns: 60% 40%;
    grid-column-gap: 2em;
    align-content: center;
    padding-top: 20px;
}
.RankInfoContainerExtended {
    display: flex;
    flex-direction: column;
}
.RankChartContainer {
    width: 100%;
    position: relative;
}
.RankInfoBox {
    width: 100%;
    display: inline-flex;
    flex-direction: column;
    word-break: keep-all;
    justify-content: center;
}
.RankInfoText {
    font-size: 16px;
}
.RankInfoTextHighlight {
    color: #D93644;
    font-size: 20px;
    font-weight: bold;
}
.RankInfoSmall {
    font-size: 12px;
}
.RankInlineImage {
    display: inline-block;
}
@media (max-width: 1200px) {
    .TSInfo {
        font-size: 16px;
    }
    .RankInfoContainer {
        display: flex;
        flex-direction: column;
    }
    .TSAmountTitle {
        font-size: 16px;
    }
    .TSAmountInfo {
        font-size: 14px;
    }
    .TSInput {
        font-size: 16px;
    }
    .TSOLScore {
        font-size: 16px;
    }

}
